import React from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, Card, CardContent, CardMedia, Button, Box, CssBaseline, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#673ab7', // Deep Purple
    },
    secondary: {
      main: '#ff4081', // Pink
    },
    background: {
      default: '#f0f0f0',
    },
  },
});

function App() {
  const createEmailLink = () => {
    const emailUser = 'craghunandan123';
    const emailDomain = 'gmail.com';
    return `${emailUser}@${emailDomain}`;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <AppBar position="relative">
          <Toolbar>
            <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h6" color="inherit" noWrap>
                Raghunandan Passport and Pest Service Solutions - Kavali
              </Typography>
            </Container>
          </Toolbar>
        </AppBar>
        <main>
          <Box
            sx={{
              bgcolor: '#673ab7',
              backgroundImage: 'url(/images/hero.webp)', // Hero banner image
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              color: '#fff',
              height: { xs: '50vh', sm: '60vh' }, // Adjust height for responsiveness
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 8, // Added margin-top to ensure image is not hidden
            }}
          >
            {/* Hero banner content can go here if needed */}
          </Box>
          <Box
            sx={{
              bgcolor: '#f3e5f5',
              pt: 8,
              pb: 6,
            }}
          >
            <Container maxWidth="lg">
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Welcome to Raghunandan Services
              </Typography>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        maxHeight: 400,
                        maxWidth: '100%',
                        borderRadius: 2,
                        boxShadow: 3,
                      }}
                      alt="Portrait"
                      src="/images/photo.jpg"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" align="center" color="textSecondary" paragraph>
                    We provide the best <b>Passport Application and Pest Control Services</b> in <b>Nellore and Kavali</b> in Andhra Pradesh. Our services are reliable, efficient, and affordable.
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      href="https://wa.me/919491340641"
                      sx={{ padding: '16px 32px', fontSize: '1.25rem' }} // Larger button
                    >
                      Contact Us on WhatsApp
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Container sx={{ py: 8 }} maxWidth="lg">
            <Typography
              component="h2"
              variant="h4"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Our Services
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={6} md={5}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 250, // Larger card image
                    }}
                    image="/images/passport.webp"
                    alt="Passport Application Services"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Passport Application Services
                    </Typography>
                    <Typography>
                      We assist you in applying for a new passport, renewing an existing one, or making amendments. Our experts ensure a hassle-free process.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 250, // Larger card image
                    }}
                    image="/images/pestcontrol.webp"
                    alt="Pest Control Services"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Pest Control Services
                    </Typography>
                    <Typography>
                      We provide effective pest control services to keep your home and office free from pests. Our solutions are safe and environmentally friendly.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </main>
        <Box sx={{ bgcolor: '#673ab7', color: '#fff', p: 6 }} component="footer">
          <Typography variant="h6" align="center" gutterBottom>
            Contact Us
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            component="p"
          >
            For more information about our services, please contact us at +91-9491340641 or email us at <Link href={`mailto:${createEmailLink()}`} color="inherit">{createEmailLink()}</Link>
          </Typography>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
